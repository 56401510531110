<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/main" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>
        {{ outletForm.full_name_mon }}
      </h3>
      <div class="action-section">
        <el-row :gutter="20">
          <el-col :span="14">
            <el-button
              type="success"
              size="mini"
              style="width:100%;"
              icon="el-icon-download"
              @click="onexport()"
              >Татаж авах(.xlsx)
            </el-button>
          </el-col>
          <el-col :span="6">
            <router-link
              :to="{
                path: 'menu',
                query: { outlet_id: $route.query._id }
              }"
            >
              <el-button
                type="success"
                v-if="$route.name === 'addPartner' ? true : false"
                size="mini"
                icon="el-icon-top-left"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
                >Цэс удирдах</el-button
              >
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row>
      <el-col
        :span="$route.name === 'addPartnerAddress' ? 24 : 22"
        :offset="$route.name === 'addPartnerAddress' ? 0 : 1"
      >
        <div class="text-center mb20">
          <el-steps :active="activeStep" align-center finish-status="success">
            <el-step
              @click.native="routeTo('partner-info')"
              title="Ерөнхий мэдээлэл"
            ></el-step>
            <el-step
              @click.native="routeTo('partner-address-hexagon')"
              title="Хаяг, хүргэлтийн бүс Hexagon"
            ></el-step>
            <el-step
              @click.native="routeTo('partner-timetable')"
              title="Цагийн хуваарь"
            ></el-step>
            <el-step
              v-if="!mArrayIncludes(role, ['ops'])"
              @click.native="routeTo('partner-additional-information')"
              title="Нэмэлт мэдээлэл"
            ></el-step>
            <el-step
              @click.native="routeTo('contractInfo')"
              title="Суурь хураамж"
            ></el-step>
            <el-step
              @click.native="routeTo('partner-additional')"
              title="Kitchen нэвтрэх эрх"
            ></el-step>
          </el-steps>
        </div>
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getGroups } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
import service from "@/helpers/services.js";
import XLSX from "xlsx";
export default {
  created() {
    getGroups().then(data => {
      this.role = data;
    });
    if (this.$route.query._id !== null) {
      this.getOutletById(this.$route.query._id);
    }
  },
  updated() {
    if (this.$route.name === "addPartner") {
      this.activeStep = 1;
    } else if (this.$route.name === "addPartnerAddress") {
      this.activeStep = 2;
    } else if (this.$route.name === "addPartnerTimetable") {
      this.activeStep = 3;
    } else if (this.$route.name === "addAdditionalInformation") {
      this.activeStep = 4;
    } else if (this.$route.name === "addPartnerAdditional") {
      this.activeStep = 5;
    }
  },
  data() {
    return {
      activeStep: 1,
      role: [],
      outletForm: {},
      downloadMenuDataPage: {
        service: "",
        outlet_name: "",
        description: "",
        rate: "",
        is_special: "",
        address: "",
        outlet_status_mon: "",
        deliveryTime: "",
        delivery_charge: "",
        badge: "",
        phone: "",
        email: ""
      },
      downloadMenuData: []
    };
  },
  methods: {
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    routeTo(path) {
      if (this.$route.query._id != null) {
        this.$router.push({
          path: path,
          query: { _id: this.$route.query._id }
        });
      }
    },
    getOutletById(id) {
      const payload = { includeFields: [] };
      service.getOutletById(id, payload).then(outletResponse => {
        this.downloadMenuDataPage.outlet_name =
          outletResponse.data.data.outlet_name;
        this.downloadMenuDataPage.description =
          outletResponse.data.data.description;
        this.downloadMenuDataPage.rate = outletResponse.data.data.percent;
        this.downloadMenuDataPage.is_special = outletResponse.data.data
          .is_special
          ? "Онцлох"
          : "Онцлох биш";
        this.downloadMenuDataPage.address = outletResponse.data.data.address;
        this.downloadMenuDataPage.badge = outletResponse.data.data.badge;
        this.downloadMenuDataPage.outlet_status_mon =
          outletResponse.data.data.outlet_status_mon;
        this.downloadMenuDataPage.phone = outletResponse.data.data.phone;
        this.downloadMenuDataPage.service = outletResponse.data.data.service;
        this.downloadMenuDataPage.email = outletResponse.data.data.email;
        this.downloadMenuData.push(this.downloadMenuDataPage);
        this.outletForm = outletResponse.data.data;
      });
    },
    onexport() {
      let reportXlsx = XLSX.utils.json_to_sheet(this.downloadMenuData);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, reportXlsx, "report");
      XLSX.writeFile(wb, this.downloadMenuData[0].outlet_name + ".xlsx");
    }
  }
};
</script>
